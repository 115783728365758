import { IGeneralDataContext } from './GeneralDataContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { extractUoUParams } from '@wix/ricos-common/libs/extractUoUParams';

export interface IGeneralDataProviderProps extends IGeneralDataContext {}

export function generalDataProviderPropsMap(
  flowAPI: ControllerFlowAPI,
): IGeneralDataProviderProps {
  const { appDefinitionId } = flowAPI.environment;
  const defaultBIParams = extractUoUParams({
    platformAPIs: flowAPI.controllerConfig.platformAPIs,
  });
  const instance =
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken(appDefinitionId);

  let instanceParsed = { instanceId: null };

  // https://wix.slack.com/archives/CAKBA7TDH/p1643623637998229
  flowAPI.controllerConfig.setProps({
    fitToContentHeight: true,
  });

  try {
    instanceParsed = JSON.parse(
      atob(instance.substr(instance.indexOf('.') + 1)),
    );
  } catch (err) {
    console.error(err);
  }

  return {
    instance,
    instanceId: instanceParsed.instanceId,
    msid: defaultBIParams.metaSiteId,
    defaultBIParams,
    startTimeOfRender: Date.now(),
  };
}
