import {
  getChallengePaidPlans,
  IUserPaidPlan,
} from '@wix/challenges-web-library';
import { MemberOrdersByPlan } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { isAfter, compareAsc, isSameDay } from 'date-fns';
import flatten from 'lodash/flatten';

import {
  isOngoing,
  isSpecific,
  isSelfPaced,
} from '../../../../../../selectors/challenges';
import { getRightDateFromBackendDateTime } from '../../../../../../selectors/dates';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';

export function isWithPaymentMethodsExceptEligiblePPs(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challenge: Challenge,
) {
  const pps = getChallengePaidPlans(challenge?.id, userPaidPlans);
  const elPPs = getChallengeEligiblePaidPlans(
    eligiblePaidPlans,
    userPaidPlans,
    challenge?.id,
  );
  const isWithSinglePayment = challenge?.settings?.pricing?.singlePayment;

  return (
    isWithSinglePayment ||
    pps.filter((plan) => !elPPs.find((elPlan) => elPlan.id === plan.id)).length
  );
}

export function isWithEligiblePPsAfterChallengeStartDate(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challenge: Challenge,
): boolean {
  const challengeStartDate =
    (isSpecific(challenge) &&
      challenge?.settings?.timelineType?.specific?.startTime) ||
    (isOngoing(challenge) &&
      challenge?.settings?.timelineType?.ongoing?.startTime);
  const isSelfPacedUnlimited =
    isSelfPaced(challenge) &&
    !challenge?.settings?.timelineType?.selfPaced?.duration?.value;
  const isFutureEligibleOnly = isChallengeHasEligiblePPsInFutureOnly(
    eligiblePaidPlans,
    userPaidPlans,
    challenge?.id,
  );

  if (isSelfPacedUnlimited && isFutureEligibleOnly) {
    return true;
  }

  return (
    challengeStartDate &&
    isFutureEligibleOnly &&
    isAfter(
      getNearestStartDateFromFutureEligiblePPs(
        eligiblePaidPlans,
        userPaidPlans,
        challenge?.id,
      ),
      getRightDateFromBackendDateTime(challengeStartDate),
    )
  );
}

export function getEligiblePlanNameByStartDate(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
  startDate: Date,
): string {
  return getChallengeEligiblePPsInFuture(
    eligiblePaidPlans,
    userPaidPlans,
    challengeId,
  ).find((elPP) =>
    elPP.memberOrders.some((order) =>
      isSameDay(startDate, new Date(order.validFrom)),
    ),
  )?.planInfo?.name;
}

export function getNearestStartDateFromFutureEligiblePPs(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): Date {
  const startDates = flatten(
    getChallengeEligiblePPsInFuture(
      eligiblePaidPlans,
      userPaidPlans,
      challengeId,
    )?.map((elPP) =>
      elPP?.memberOrders
        ?.map((order) => order.validFrom && new Date(order.validFrom))
        .filter((item) => !!item),
    ),
  )
    .filter((item) => !!item)
    .sort(compareAsc);

  return startDates?.[0];
}

export function isChallengeHasEligiblePPsInFutureOnly(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): boolean {
  return !!(
    !getChallengeEligiblePPsAvailableNow(
      eligiblePaidPlans,
      userPaidPlans,
      challengeId,
    )?.length &&
    getChallengeEligiblePPsInFuture(
      eligiblePaidPlans,
      userPaidPlans,
      challengeId,
    )?.length
  );
}

export function getChallengeEligiblePPsInFuture(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): MemberOrdersByPlan[] {
  return getChallengeEligiblePaidPlans(
    eligiblePaidPlans,
    userPaidPlans,
    challengeId,
  )
    ?.map((pp) => getEligiblePlanById(pp.id, eligiblePaidPlans))
    .filter((elPP) => {
      return elPP?.memberOrders?.every((order) => {
        const validFrom = order.validFrom;

        return validFrom && isAfter(new Date(validFrom), new Date());
      });
    });
}

export function getChallengeEligiblePPsAvailableNow(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): MemberOrdersByPlan[] {
  return getChallengeEligiblePaidPlans(
    eligiblePaidPlans,
    userPaidPlans,
    challengeId,
  )
    ?.map((pp) => getEligiblePlanById(pp.id, eligiblePaidPlans))
    .filter((elPP) => {
      return elPP?.memberOrders?.some((order) => {
        const validFrom = order.validFrom;

        return !(validFrom && isAfter(new Date(validFrom), new Date()));
      });
    });
}

export function getEligiblePlanById(
  planId: string,
  eligiblePaidPlans: MemberOrdersByPlan[],
) {
  return eligiblePaidPlans.find((plan) => plan.planInfo.id === planId);
}

export function getChallengeEligiblePaidPlans(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): IUserPaidPlan[] {
  const challengePaidPlans = getChallengePaidPlans(challengeId, userPaidPlans);

  if (!challengePaidPlans.length || !eligiblePaidPlans.length) {
    return [];
  }

  return challengePaidPlans.filter((paidPlan) =>
    eligiblePaidPlans.some(
      (eligiblePaidPlan) => eligiblePaidPlan.planInfo.id === paidPlan.id,
    ),
  );
}
