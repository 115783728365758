import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { AccessType } from '@wix/ambassador-challenges-v1-challenge/types';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import { challengeDataProviderPropsMap } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { acceptInvite } from './acceptInvite';
import { sendRequestForOwnersApprove } from './sendRequestForOwnersApprove';
import { joinWithoutOwnerApprove } from './joinWithoutOwnerApprove';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { IUserProviderProps } from '../UserProvider';
import { handleError } from '../../ErrorHandler/errorHandlerPropsMap';

export const createParticipant = async ({
  timeZone,
  startDateFormatted,
  flowAPI,
  selectedPaymentOption,
  userProvider,
}: {
  timeZone: string;
  userProvider: IUserProviderProps;
  startDateFormatted?: string;
  flowAPI: ControllerFlowAPI;
  selectedPaymentOption: SelectedPaymentOption;
}): Promise<Participant> => {
  try {
    const participant = userProvider.participant;
    const participantTransitionState: ParticipantState | undefined =
      participant?.transitions?.length && participant?.transitions['0'].state;
    const { challengeData } = await challengeDataProviderPropsMap(flowAPI);

    const challengeAccessType =
      challengeData.challenge?.settings?.accessRestrictions?.accessType;

    switch (participantTransitionState) {
      case ParticipantState.INVITED:
        await acceptInvite(flowAPI, timeZone, startDateFormatted);
        await userProvider.updateParticipant();
        return userProvider.participant;
      case ParticipantState.JOIN_REJECTED:
        await userProvider.updateParticipant(
          await sendRequestForOwnersApprove(
            flowAPI,
            timeZone,
            startDateFormatted,
          ),
        );
        await userProvider.updateParticipant(); // remove it when backend will be fixed https://jira.wixpress.com/browse/CHAL-981
        return userProvider.participant;
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.JOIN_REQUESTED:
      case ParticipantState.JOINED:
      case ParticipantState.RUNNING:
      case ParticipantState.COMPLETED:
      case ParticipantState.FAILED:
      case ParticipantState.SUSPENDED:
        return userProvider.participant;
      case ParticipantState.REMOVED:
      case ParticipantState.LEFT:
      default: // todo remove when margo will fix issue
        if (challengeAccessType === AccessType.PRIVATE) {
          await userProvider.updateParticipant(
            await sendRequestForOwnersApprove(
              flowAPI,
              timeZone,
              startDateFormatted,
            ),
          );
          await userProvider.updateParticipant(); // remove when backend will be fixed https://jira.wixpress.com/browse/CHAL-981
          return userProvider.participant;
        }
        await userProvider.updateParticipant(
          await joinWithoutOwnerApprove(flowAPI, timeZone, startDateFormatted),
        );
        await userProvider.updateParticipant();
        return userProvider.participant;
    }
  } catch (error) {
    handleError({
      error,
      context: 'createParticipant',
    });
  }
  await userProvider.updateParticipant();
  return userProvider.participant;
};
