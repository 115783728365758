import { ILoadingProps } from './interfaces';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

let loading = null;

export function loadingPropsMap(flowAPI: ControllerFlowAPI): ILoadingProps {
  if (loading) {
    return loading;
  }
  loading = {
    isLoading: false,
    showLoader() {
      flowAPI.controllerConfig.setProps({
        isLoading: true,
      } as Partial<ILoadingProps>);
      loading.isLoading = true;
    },
    hideLoader() {
      flowAPI.controllerConfig.setProps({
        isLoading: false,
      } as Partial<ILoadingProps>);
      loading.isLoading = false;
    },
  };
  return loading;
}
