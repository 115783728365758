import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

let errorHandler: IErrorHandlerPropsMap = null;

interface IHandleErrorArgs {
  error: any;
  context?: any;
  muteError?: boolean;
  preventErrorMonitorReport?: boolean;
}

export interface IErrorHandlerPropsMap {
  errorMessageKey?: string;
  handleError({ error, context }: IHandleErrorArgs): void;
  showError(errorMessage: string): void;
  clearError(): void;
}

export enum ServerKnownErrorCode {
  Auth = 'authorization_error',
  AccessError = 'access_error',
  SectionNotFound = 'section_not_found',
  StepNotFound = 'step_not_found',
  DuplicatedParticipantOwner = 'duplicated_participant_owner',
  DuplicatedParticipantMember = 'duplicated_participant_member',
  ApproveAlreadyResolved = 'approval_already_resolved',
}

interface IServerErrorMessage {
  data?: {
    details?: {
      traceId?: string;
      applicationError?: { code?: ServerKnownErrorCode; description?: string };
    };
    message?: string;
  };
}

function getErrorMessageKeyByServerError(error: any): string | undefined {
  const response: IServerErrorMessage = error?.response;

  switch (response?.data?.details?.applicationError?.code) {
    case ServerKnownErrorCode.Auth:
      return 'toast.error.permission-denied';
    case ServerKnownErrorCode.DuplicatedParticipantOwner:
      return 'toast.error.duplicate-owner';
    case ServerKnownErrorCode.DuplicatedParticipantMember:
      return 'toast.error.duplicate-member';
    case ServerKnownErrorCode.ApproveAlreadyResolved:
      return 'toast.error.resolved';
    default:
    case ServerKnownErrorCode.AccessError:
    case ServerKnownErrorCode.SectionNotFound:
    case ServerKnownErrorCode.StepNotFound:
      return 'toast.error.server-general';
  }
}

export const errorHandlerPropsMap = async (
  flowAPI?: ControllerFlowAPI,
): Promise<IErrorHandlerPropsMap> => {
  if (errorHandler === null) {
    errorHandler = {
      handleError({ error, context, preventErrorMonitorReport, muteError }) {
        const errorMessageKey = getErrorMessageKeyByServerError(error);

        if (!preventErrorMonitorReport) {
          flowAPI?.errorMonitor?.captureMessage(
            `[http request failed]: ${JSON.stringify({
              message: error?.message,
              response: error?.response,
              context,
            })}`,
          );
        }
        if (errorMessageKey && !muteError) {
          this.showError(errorMessageKey);
        }
      },
      showError(errorMessageKey) {
        flowAPI?.controllerConfig?.setProps({
          errorMessageKey,
        } as Partial<IErrorHandlerPropsMap>);
      },
      clearError() {
        flowAPI?.controllerConfig?.setProps({
          errorMessageKey: undefined,
        } as Partial<IErrorHandlerPropsMap>);
      },
    };
  }

  return errorHandler;
};

export function handleError(args: IHandleErrorArgs) {
  errorHandlerPropsMap()
    .then((handler) => {
      handler.handleError(args);
    })
    .catch(console.error);
}
