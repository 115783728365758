import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  MA_CHALLENGES_LIST_PAGE_TYPE,
  MA_PROGRAMS_LIST_PAGE_TYPE,
} from '../editor/app-config';

export const isMA = (flowAPI: ControllerFlowAPI): boolean => {
  return (
    flowAPI.environment.widgetId === MA_CHALLENGES_LIST_PAGE_TYPE ||
    flowAPI.environment.widgetId === MA_PROGRAMS_LIST_PAGE_TYPE
  );
};
