import React from 'react';

import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  State as ParticipantState,
  Participant,
} from '@wix/ambassador-challenges-v1-participant/types';
import {
  CancelJoinRequestFunction,
  JoinFunction,
  LeaveTheChallengeFunction,
} from './userContextInterface';
import { IUserProviderProps } from './UserProvider';
import { UpdatedUserData } from './helpers/userContextHelpers';

// https://www.wix.com/corvid/reference/wix-users.html#LoginOptions
export interface IPromptLogin {
  mode?: 'login' | 'signup';
  lang?: string;
}

export enum UserState {
  VISITOR = 'VISITOR',
  MEMBER = 'MEMBER',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
}

export type IUserType = UserState | ParticipantState;
export type PlatformUser = Pick<IUser, 'id' | 'instance' | 'loggedIn' | 'role'>;
export interface IUserContext {
  user: PlatformUser;
  userType: IUserType;
  isParticipantInSuspendedState?: boolean;
  participant?: Participant;
  promptLogin(options?: IPromptLogin): Promise<any>;
  join: JoinFunction;
  cancelJoinRequest: CancelJoinRequestFunction;
  leaveTheChallenge: LeaveTheChallengeFunction;
  incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData>;
  updateParticipant(
    participant?: Participant,
  ): Promise<Partial<IUserProviderProps>>;
  userTypeHandlers: {
    [index: string]: (userType: IUserType) => boolean;
  };
}

export const userContextDefaultValue = {
  user: null,
  userType: null,
  isParticipantInSuspendedState: false,
  participant: null,
  promptLogin: null,
  join: null,
  async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
    return {};
  },
  async updateParticipant() {
    return {};
  },
  cancelJoinRequest: null,
  leaveTheChallenge: null,
  userTypeHandlers: null,
};

export const UserContext = React.createContext<IUserContext>(
  userContextDefaultValue,
);

export function useUser(): IUserContext {
  return React.useContext(UserContext);
}

export const UserConsumer = UserContext.Consumer;
