import {
  Feedback,
  QuizSubmission,
  ResolutionStatus,
} from '@wix/ambassador-challenges-v1-participant/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IResolveStepContext } from './ResolveStepContext';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { ActionTypes } from '../main/biInterfaces';
import { handleError } from '../ErrorHandler/errorHandlerPropsMap';
import { getChallengeId } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { biBeforeAndAfter } from '../../services/biHelpers';
import {
  resolveStep as rStep,
  updateStepFeedback,
} from '@wix/ambassador-challenges-v1-participant/http';
import { request } from '../../services/request';

import { FedopsInteraction } from '../../config/fedopsInteraction';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';

const resolveStep = async (
  flowAPI: ControllerFlowAPI,
  stepId: string,
  feedback?: Feedback,
  quizSubmission?: QuizSubmission,
  isUpdate: boolean = false,
): Promise<void> => {
  const { participant } = await userProviderPropsMap(flowAPI);

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: true,
  });

  const sendRequest = async (actionId) => {
    const requestData: any = {
      actionId,
      challengeId: await getChallengeId(flowAPI),
      participantId: participant?.id,
      status: ResolutionStatus.COMPLETED,
      stepId,
    };

    if (feedback) {
      requestData.feedback = {
        createdAt: new Date(),
        items: feedback?.items,
        quiz: feedback?.quiz,
      };
    } else if (quizSubmission) {
      requestData.quizSubmissionId = quizSubmission.quizSubmissionId;
      requestData.status = ResolutionStatus.QUIZ_SUBMIT;
    }

    if (isUpdate) {
      delete requestData.feedback.createdAt;
      delete requestData.status;

      requestData.feedback.updatedAt = new Date();
    }

    return !isUpdate
      ? (await request(flowAPI, rStep, requestData))?.data
      : (await request(flowAPI, updateStepFeedback, requestData))?.data;
  };

  try {
    await biBeforeAndAfter(
      flowAPI.bi,
      !isUpdate ? ActionTypes.STEP_COMPLETE : ActionTypes.STEP_FEEDBACK_UPDATE,
      async (actionId) => {
        return feedback?.items?.length || feedback?.quiz?.length
          ? biBeforeAndAfter(flowAPI.bi, ActionTypes.FEEDBACK_SEND, () => {
              return sendRequest(actionId);
            })
          : sendRequest(actionId);
      },
    );

    if (!isUpdate) {
      const userProvider = await userProviderPropsMap(flowAPI);
      await userProvider.updateParticipant();
    }

    const { endInteraction } = await monitoringPropsMap(flowAPI);
    const interactionName = isUpdate
      ? FedopsInteraction.UpdateFeedbackData
      : feedback?.items?.length
      ? FedopsInteraction.CompleteStepWithFeedback
      : FedopsInteraction.CompleteStepWithoutFeedback;
    endInteraction(interactionName, stepId);
  } catch (error) {
    handleError({
      error,
      context: 'resolveStep',
    });

    flowAPI.controllerConfig.setProps({
      resolveStepError: error.toString(),
    });

    console.error(error);
  }

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: false,
  });
};

export const resolveStepDataProviderPropsMap = async function (
  flowAPI: ControllerFlowAPI,
): Promise<IResolveStepContext> {
  return {
    isResolveStepRequestInProgress: false,
    resolveStep: async (stepId, feedback?, quizSubmission?, isUpdate = false) =>
      resolveStep(flowAPI, stepId, feedback, quizSubmission, isUpdate),
    resolveStepError: null,
  };
};
