import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;

  const { isEditor } = flowAPI.environment;

  return {
    async pageReady() {
      try {
        const props = await providersPropsMap({
          biSettings: {
            preventAutomaticallySendChallengeId: isEditor,
            screenName: ScreenNames.ThankYouPage,
          },
          flowAPI,
          enabledProviders: [
            'challengeDataProvider',
            'inviteLinkProvider',
            'participantProvider',
          ],
        });
        const initialProps = {
          ...props,
        };
        setProps(initialProps);
      } catch (err) {
        console.error(err);
      }
    },
  };
};

export default createController;
