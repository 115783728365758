import { IProvidersProps } from './interfaces';
import { ISettingsParams } from '@wix/tpa-settings';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { challengeDataProviderPropsMap } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { IBiSettings } from './biInterfaces';
import { inviteLinkPropsMap } from '../InviteLink/inviteLinkPropsMap';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { paidPlansPropsMap } from '../PaidPlans/paidPlansPropsMap';
import { participantStepsDataProviderPropsMap } from '../ParticipantStepsDataProvider/ParticipantStepsDataProviderPropsMap';
import { participantSectionsPropsMap } from '../ParticipantSections/ParticipantSectionsPropsMap';
import { resolveStepDataProviderPropsMap } from '../ResolveStep/ResolveStepDataProviderPropsMap';
import { loadingPropsMap } from '../Loading/loadingPropsMap';
import { errorHandlerPropsMap } from '../ErrorHandler/errorHandlerPropsMap';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';
import { categoriesDataProviderPropsMap } from '../CategoriesDataProvider';
import { badgesProviderPropsMap } from '../Badges/BadgeProviderPropsMap';
import { socialGroupsPropsMap } from '../SocialGroups/SocialGroupsPropsMap';
import { fileUploadProviderPropsMap } from '../FileUpload/FileUploadPropsMap';
import { monitoringPropsMap } from '../Monitoring/MonitoringPropsMap';
import { quizPropsMap } from '../Quiz/QuizPropsMap';

export type ToggleableProviders =
  | 'inviteLinkProvider'
  | 'categoriesDataProvider'
  | 'challengeDataProvider'
  | 'challengesListDataProvider'
  | 'paidPlansProvider'
  | 'participantStepsDataProvider'
  | 'participantSectionsDataProvider'
  | 'resolveStepDataProvider'
  | 'badgesProvider'
  | 'socialGroupsProvider'
  | 'fileUploadProvider'
  | 'participantProvider';

export const providers: Record<
  ToggleableProviders,
  (flowAPI: ControllerFlowAPI) => any
> = {
  categoriesDataProvider: categoriesDataProviderPropsMap,
  challengeDataProvider: challengeDataProviderPropsMap,
  challengesListDataProvider: challengesListDataProviderPropsMap,
  inviteLinkProvider: inviteLinkPropsMap,
  paidPlansProvider: paidPlansPropsMap,
  participantStepsDataProvider: participantStepsDataProviderPropsMap,
  participantSectionsDataProvider: participantSectionsPropsMap,
  resolveStepDataProvider: resolveStepDataProviderPropsMap,
  badgesProvider: badgesProviderPropsMap,
  socialGroupsProvider: socialGroupsPropsMap,
  fileUploadProvider: fileUploadProviderPropsMap,
  participantProvider: userProviderPropsMap,
};

interface IRootProviderPropsMap<T> {
  biSettings?: IBiSettings;
  enabledProviders: ToggleableProviders[];
  flowAPI: ControllerFlowAPI;
}

export async function providersPropsMap<T extends ISettingsParams>(
  options: IRootProviderPropsMap<T>,
): Promise<IProvidersProps> {
  const { enabledProviders, flowAPI } = options;

  const providersProps = {
    ...generalDataProviderPropsMap(flowAPI),
    ...locationProviderPropsMap(flowAPI),
    ...loadingPropsMap(flowAPI),
    ...localStoragePropsMap(flowAPI),
    ...monitoringPropsMap(flowAPI),
    ...quizPropsMap(flowAPI),
  };

  const asyncProviders: Promise<any>[] = [errorHandlerPropsMap(flowAPI)];

  enabledProviders.forEach((providerName) => {
    asyncProviders.push(providers[providerName](flowAPI));
  });

  const data = await Promise.all(asyncProviders);
  data.forEach((providerData) => {
    if (!providerData) {
      return;
    }
    Object.entries(providerData).forEach(([key, value]) => {
      providersProps[key] = value;
    });
  });

  return providersProps as any;
}
