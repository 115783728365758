import { ControllerFlowAPI, IWixWindow } from '@wix/yoshi-flow-editor';

export class WarmupData {
  static renderingEnv: string;
  private _warmupData: IWixWindow['warmupData'];

  constructor(flowAPI: ControllerFlowAPI) {
    WarmupData.renderingEnv =
      flowAPI.controllerConfig.wixCodeApi.window.rendering.env;

    this._warmupData = flowAPI.controllerConfig.wixCodeApi.window.warmupData;
  }

  get(key: string) {
    return this._warmupData.get(key);
  }

  set(key: string, data: unknown) {
    if (WarmupData.renderingEnv === 'backend') {
      this._warmupData.set(key, data);
    }
  }
}
