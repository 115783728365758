import uuid from 'uuid';
import {
  memberWebAppOnBeforeAction,
  memberWebAppScreenOpen,
  memberWebOnAfterAction,
} from '@wix/bi-logger-challenges-member-web/v2';
import { ActionTypes, ScreenNames } from '../contexts/main/biInterfaces';

export const biBeforeAndAfter = async (
  bi: any,
  actionType: ActionTypes,
  fn: Function,
) => {
  const actionId = uuid();

  await bi.report(
    memberWebAppOnBeforeAction({
      actionId,
      actionType,
      // screenName,
    }),
  );

  const response = await fn(actionId);

  await bi.report(
    memberWebOnAfterAction({
      actionId,
      actionType,
      // screenName,
    }),
  );

  return response;
};

export const biChangePage = async (bi: any, screenName: ScreenNames) => {
  await bi.report(
    memberWebAppScreenOpen({
      screenName,
    }),
  );

  await bi.updateDefaults({
    screenName,
  });
};
